import {
  REQUEST_SCREEN,
  REQUEST_SCREEN_SUCCESS,
  RequestScreenAction,
  RequestScreenSuccessAction,
  ScreenState,
  UPDATE_SELF_SCREEN_DEVICE_SUCCESS,
  UpdateSelfScreenDeviceSuccessAction,
  ActiveItem,
  UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD,
  UpdateCurrentActiveItemWithChildAction,
  UPDATE_SCREEN_DEVICE_INFO_SUCCESS,
  UpdateScreenDeviceInfoSuccessAction,
} from "./types";
import { normalize } from "normalizr";
import { ContentViewItem } from "../../types/content";
import { DEFAULT_SIZE_TYPE_FALLBACK } from "../../constants";
import { CurrentScreenQuery, Screen, ScreenByIdQuery } from "../../queries";
import { NormalizedScreenEntities, screenNormalization } from "./normalization";
import { TimelinePlayback } from "../timelines/types";

export function requestScreen(): RequestScreenAction {
  return {
    type: REQUEST_SCREEN,
  };
}

/**
 * This action creator will parse the API response into the various normalized objects here.
 * More efficient as each reducer doesn't then have to parse the entire response itself.
 *
 * NB - Normalizr will replace a normalized child with its ID.
 * Look at NormalizedGqlChannel in types.ts as an example of safely informing TS of this.
 */

export function requestScreenSuccess(
  response: ScreenByIdQuery | CurrentScreenQuery
): RequestScreenSuccessAction | undefined {
  const screenData =
    "currentScreen" in response
      ? response.currentScreen
      : (response as ScreenByIdQuery).screenById;

  if (!screenData) {
    return undefined;
  }

  const targetContentRef = screenData.castByCastId
    ? screenData.castByCastId.content
    : screenData.content;

  let activeContentItem: ContentViewItem;

  switch (targetContentRef._ref?.type) {
    case "file":
    case "app":
    case "link":
    case "site":
      activeContentItem = {
        type: targetContentRef._ref.type,
        id: targetContentRef._ref.id,
        sizeType: DEFAULT_SIZE_TYPE_FALLBACK,
        fullDurationMs: Infinity,
      };
      break;
    case "channel":
      activeContentItem = {
        type: targetContentRef._ref.type,
        id: targetContentRef._ref.id,
      };
      break;
    case "playlist":
      activeContentItem = {
        type: targetContentRef._ref.type,
        id: targetContentRef._ref.id,
      };
      break;
    default:
      if (targetContentRef.href) {
        activeContentItem = {
          type: "href",
          href: targetContentRef.href,
          id: targetContentRef.href,
          fullDurationMs: Infinity,
          meta: targetContentRef.meta,
          props: targetContentRef.props,
        };
      } else {
        activeContentItem = {
          type: "void",
        };
      }
  }

  const screen: ScreenState = {
    activeContentItem,
    id: screenData.id,
    deviceId: screenData.deviceId,
    name: screenData.name,
    screenData: screenData.env,
    spaceId: screenData.spaceBySpaceId?.id || null,
    operatingHours: screenData.preferences.operating,
    isMuted: !!screenData.preferences.is_muted,
    deviceInfo: screenData.deviceInfo,
    status: screenData.status,
    isPreview: screenData.preview,
    playbackMode: screenData.playbackMode as TimelinePlayback,
    videoSource: screenData.videoSource,
    highResolutionImage: screenData.highResolutionImage,
    timezoneOverride: screenData.timezoneOverride,
    deviceModel: screenData.deviceModel,
    devicePlatform: screenData.devicePlatform,
  };

  const normalizedData = normalize<unknown, NormalizedScreenEntities>(
    screenData,
    screenNormalization.schema
  );

  const organization = normalizedData.entities.orgs
    ? Object.values(normalizedData.entities.orgs)[0]
    : undefined;

  return {
    type: REQUEST_SCREEN_SUCCESS,
    payload: {
      apps: normalizedData.entities.apps || {},
      channels: normalizedData.entities.channels || {},
      files: normalizedData.entities.files || {},
      layouts: normalizedData.entities.layouts || {},
      links: normalizedData.entities.links || {},
      organization: organization,
      playlists: normalizedData.entities.playlists || {},
      screen,
      themes: normalizedData.entities.themes || {},
      spaces: normalizedData.entities.spaces || {},
      sites: normalizedData.entities.sites || {},
    },
  };
}

export function updateSelfScreenDeviceSuccess(
  payload: Partial<
    Pick<
      Screen,
      | "deviceHostname"
      | "deviceIpAddress"
      | "playerRelease"
      | "playerHeight"
      | "playerWidth"
      | "playerTimezone"
    >
  >
): UpdateSelfScreenDeviceSuccessAction {
  return {
    type: UPDATE_SELF_SCREEN_DEVICE_SUCCESS,
    payload: payload,
  };
}

export function updateScreenDeviceInfoSuccess(
  payload: Partial<Pick<Screen, "deviceInfo" | "deviceRaw">>
): UpdateScreenDeviceInfoSuccessAction {
  return {
    type: UPDATE_SCREEN_DEVICE_INFO_SUCCESS,
    payload: payload,
  };
}

export function updateCurrentActiveItemWithChild(
  activeItems: ActiveItem
): UpdateCurrentActiveItemWithChildAction {
  return {
    type: UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD,
    payload: {
      activeItems,
    },
  };
}
